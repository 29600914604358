import React from 'react'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const Photos = () => {
  const { t } = useTranslation()

  const [slideNumber, setSlideNumber] = useState(0)

  const [openCasa, setOpenCasa] = useState(false)



  const handleOpenCasa = (index) => {
    setSlideNumber(index)
    setOpenCasa(true)
  }



  const handleMoveCasa = (direction) => {
    let newSlideNumber
    if (direction === 'l') {
      newSlideNumber = slideNumber === 0 ? 2 : slideNumber - 1
    } else {
      newSlideNumber = slideNumber === 2 ? 0 : slideNumber + 1
    }

    setSlideNumber(newSlideNumber)
  }


  const imagescasa = [
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837134/Tapada/IMG_6514_copie_zmilnm.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837129/Tapada/IMG_6525_copie_qzwgok.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837127/Tapada/IMG_6477_nnpmkb.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837121/Tapada/IMG_6490_copie_vzvcch.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837087/Tapada/IMG_6499_copie_k2vs7h.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837068/Tapada/IMG_6492_copie_fxr3md.jpg',
    },

    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837071/Tapada/IMG_6465_mrd3c3.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837052/Tapada/IMG_6447_neg7u8.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837053/Tapada/IMG_6430_aon94y.jpg',
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837041/Tapada/IMG_6422_l487ep.jpg'
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837120/Tapada/IMG_6550_copie_tfodj4.jpg'
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837099/Tapada/IMG_6468_zbzy4t.jpg'
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837097/Tapada/IMG_6435_c4lnlq.jpg'
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837039/Tapada/IMG_6420_copie_gqc8y4.jpg'
    },
    {
      src:
        'https://res.cloudinary.com/dowjj9cdi/image/upload/v1693837022/Tapada/IMG_6370_copie_tutdvn.jpg'
    }
  ]



  return (
    <>
      {openCasa && (
        <div className="sticky inset-0 w-screen h-screen bg-black bg-opacity-60 flex items-center z-30">
          <FontAwesomeIcon
            icon={faXmark}
            className="absolute top-[20px] right-[20px] text-[30px] text-agreen cursor-pointer"
            onClick={() => setOpenCasa(false)}
          />

          <FontAwesomeIcon
            icon={faArrowLeft}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveCasa('l')}
          />

          <div className="h-full w-full flex justify-center items-center">
            <img
              src={imagescasa[slideNumber].src}
              alt=":)"
              className="lg:w-4/5 w-full h-4/5 object-cover"
            />
          </div>

          <FontAwesomeIcon
            icon={faArrowRight}
            className="m-[20px] text-[50px] text-agreen cursor-pointer"
            onClick={() => handleMoveCasa('r')}
          />
        </div>
      )}

      <div>
        <p className="text-4xl flex justify-center text-center font-title text-agreen py-20">
          {t('photos.title')}
        </p>
      </div>

      <div className="md:flex-row md:flex md:flex-wrap mx-8">
        {imagescasa.map((image, index) => (
          <img
            src={image.src}
            alt="gallery"
            className="md:w-1/3 h-[300px] object-cover cursor-pointer p-3"
            onClick={() => handleOpenCasa(index)}
          />
        ))}
      </div>
    </>
  )
}

export default Photos
