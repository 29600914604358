import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lang", lng);
    setIsOpen(false);
    setActiveLanguage(lng); // Set the active language when it's clicked
  };

  return (
    <>
      <div className="relative inline-block">
        <button
          className="text-sm border-1 border-ayellow font-medium font-text rounded-md outline-none ring-2 ring-offset-2 ring-offset-gray-100 ring-ayellow px-4"
          onClick={toggleMenu}
        >
          {t(`language.${activeLanguage}`)}
        </button>
        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 sm:w-48 md:w-64">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button
                className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-right md:text-left ${
                  activeLanguage === "en" ? "bg-gray-300" : ""
                }`}
                onClick={() => changeLanguage("en")}
                disabled={activeLanguage === "en"}
              >
                {t("language.english")}
              </button>
              <button
                className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-right md:text-left ${
                  activeLanguage === "fr" ? "bg-gray-300" : ""
                }`}
                onClick={() => changeLanguage("fr")}
                disabled={activeLanguage === "fr"}
              >
                {t("language.français")}
              </button>
              <button
                className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-right md:text-left ${
                  activeLanguage === "pt" ? "bg-gray-300" : ""
                }`}
                onClick={() => changeLanguage("pt")}
                disabled={activeLanguage === "pt"}
              >
                {t("language.português")}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LanguageSwitcher;
