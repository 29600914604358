import React from 'react'
import mapboxgl from 'mapbox-gl'
import { useEffect, useRef } from 'react'

import 'mapbox-gl/dist/mapbox-gl.css'

const Mapbox = () => {
  const mapContainer = useRef(null)

  useEffect(() => {
    mapboxgl.accessToken =
      'pk.eyJ1Ijoiem9ldmFucCIsImEiOiJjbGh0YndkZnIwZnI2M2RvMW1jeW5panc1In0.UWkhNbC5enevxON2RS5Kvw'

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-7.624634317589059, 39.405408939293494],
      zoom: 12,
    })

    map.on('load', function () {
      const marker = new mapboxgl.Marker()
        .setLngLat([-7.624634317589059, 39.405408939293494])
        .addTo(map)
    })

    return () => {
      map.remove()
    }
  }, [])

  // 43.83265930420983, 5.419450335703744

  // 39.405408939293494, -7.624634317589059

  // ,

  return (
    <>
      <div className="">
        <div
          ref={mapContainer}
          className="md:w-[500px] w-[300px] md:h-[500px] h-[300px] border-2 border-agreen rounded"
        />
      </div>
    </>
  )
}

export default Mapbox
