import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Photos from './pages/Photos'
import React from 'react'

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element= {<Home />}/>
        <Route path='/contact' element = {<Contact />}/>
        <Route path='/photos' element= {<Photos />}/>
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
