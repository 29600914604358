import React from 'react'
import { AiOutlineInstagram } from 'react-icons/ai'
import { AiOutlineFacebook } from 'react-icons/ai'
import { RxArrowUp } from 'react-icons/rx'
import lreimg from '../images/LRE_Theme.Logo_White.png'
import bioimg from '../images/1554.png'
import {motion} from 'framer-motion'
import Tractor from '../videos/tracteur2.gif'
import {useState} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// YELLOW
const Footer = () => {
  const {t} = useTranslation()

  const [isExpanded, setIsExpanded] = useState(false);

  const tractorVariants = {
    initial: { x: '100%', opacity: 1 },
    animate: {
      x: ['500%', '-100%'],
      opacity: [1, 1],
      transition: { duration: 12, ease: 'linear', repeat: Infinity },
    },
  };

  const handleFooterHover = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="relative h-full min-h-[35vh] ">
      <div className="bg-ayellow justify-center bottom-0 left-0 absolute w-[100%] h-14 hover:h-80 ease-in duration-300 group"
      onMouseEnter={handleFooterHover}
      onMouseLeave={handleFooterHover}>
        <div className="flex justify-between ml-2 mr-2 mt-4 ">
          <div className="font-text text-sm mx-5"> Tadapa dos Ribeiros</div>
          <div className="group-hover:rotate-180 duration-300">
            {/* <div className="text-awhite  ml-4  animate-bounce ">{arrowUp}</div> */}
            <div className="text-white animate-bounce transform ">
              <RxArrowUp />
            </div>
          </div>
          <div className="font-text text-sm mx-5">
            <a
              href="https://www.dos-atelier.com"
              rel="noreferrer"
              target="_blank"
            >
              designed by DOS Atelier
            </a>{' '}
          </div>
        </div>
        <div className="hidden group-hover:block ml-2 mr-2 ">
          <div className="flex justify-around mt-10">
            <div>
              <div className="flex">
                <div>
                  <Link to='https://www.facebook.com/quintadosribeiros' target='_blank'>
                  <AiOutlineFacebook size={40} color="" />
                  </Link>
                </div>
                <div>
                  <Link to='https://www.instagram.com/quintadosribeiros/' target='_blank'>
                  <AiOutlineInstagram size={40} />
                  </Link>
                </div>
              </div>
              <div className="mt-10">
                <div>
                  <img className="w-28" src={lreimg} alt="" />
                </div>
                <div>
                  <img className="w-14 mt-5" src={bioimg} alt="" />
                </div>
              </div>
            </div>
            <div className="">
              <div>
                <div className="font-title md:text-lg">Mobile</div>
                <div className="font-title text-xs md:text-base">
                (+351) 961 444 039
                <p>{t('footer.chamada')}</p>
                </div>
              </div>
              <div className="mt-5">
                <div className="font-title md:text-lg">Email</div>
                <div className="font-title text-xs md:text-base">
                  info@tapadadosribeiros.com
                </div>
              </div>
              <div className="font-title w-40 text-xs mt-20 md:mt-10 md:w-[100%]">
                © Tapada dos Ribeiros todos os direitos reservados RNET 5612
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isExpanded && (

      <motion.div
      className="absolute md:top-[-15px] top-24 left-0 md:h-full pointer-events-none"
      initial="initial"
      animate="animate"
      variants={tractorVariants}
      transition={{repeat: Infinity }}
    >
      <img src={Tractor} alt="Tractor" className="h-24 md:h-32 lg:h-48 xl:h-64 w-auto object-contain" />
    </motion.div>
      )}
    </div>
  )
}

export default Footer

//
