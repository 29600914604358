import React from 'react'
import Logo from '../images/logo.png'
import { RxHamburgerMenu } from 'react-icons/rx'
import { AiOutlineClose } from 'react-icons/ai'
import { useState, useEffect } from 'react'
import {Link, useLocation } from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import LanguageSwitcher from './LanguageSwitcher'

const Navbar = () => {
  const {t} = useTranslation()

  let [nav, setNav] = useState(false)
  const handleClick = ()=>{
    setNav(!nav)
  }

  const location = useLocation()
  const [activeItem, setActiveItem] =useState('home')

  useEffect(()=>{
    const pathname = location.pathname
    if (pathname === '/') {
      setActiveItem('home')
    } else if (pathname === '/contact'){
      setActiveItem('contact')
    } else if (pathname === '/photos'){
      setActiveItem('photos')
    }
  }, [location.pathname])


  return (
    <div className="w-full top-0 left-0 z-10">
      <div className="md:flex items-center justify-between bg-awhite py-4 md:px-10 px-7 relative">
        <div className="cursor-pointer w-[30%]">
          <span className="mr-1 pt-2">
            <Link to="/">
              <img src={Logo} alt="logo quinta dos ribeiros" className="w-[80px] md:w-[25%]" />
            </Link>
          </span>
        </div>
        <div
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
          onClick={handleClick}
        >
          {nav ? <AiOutlineClose size={20} /> : <RxHamburgerMenu size={20} />}
        </div>
        <ul
          className={`md:flex md:items-center bg-awhite md:bg-transparent absolute font-text md:right-10 md:pb-0 pb-12 w-full ml-[-25px] px-10 md:w-auto z-10 ${
            nav ? 'top-20 opacity-100' : 'hidden md:opacity-100 opacity-0'
          }`}
        >
          <li className="md:my-0 my-7">
            <Link
              to="/"
              className={`ml-8 text-lg hover:text-agreen duration-500 ${
                activeItem === 'home' ? 'text-agreen font-bold' : ''
              }`}
            >
             {t('navbar.home')}
            </Link>
          </li>
          <li className="md:my-0 my-7">
            <Link
              to="https://www.quintadosribeiros.com/farm"
              target="_blank"
              className={`ml-8 text-lg hover:text-agreen duration-500 ${
                activeItem === 'farm' ? 'text-agreen font-bold' : ''
              }`}
            >
             {t('navbar.farm')}
            </Link>
          </li>
          <li className="md:my-0 my-7">
            <Link
              to="https://www.quintadosribeiros.com/region"
              target='_blank'
              className={`ml-8 text-lg hover:text-agreen duration-500 ${
                activeItem === 'region' ? 'text-agreen font-bold' : ''
              }`}
            >
             {t('navbar.region')}
            </Link>
          </li>
          <li className="md:my-0 my-7">
            <Link
              to="/photos"
              className={`ml-8 text-lg hover:text-agreen duration-500 ${
                activeItem === 'photos' ? 'text-agreen font-bold' : ''
              }`}
            >
             {t('navbar.photos')}
            </Link>
          </li>
          <li className="md:my-0 my-7">
            <Link
              to="/contact"
              className={`ml-8 text-lg hover:text-agreen duration-500 ${
                activeItem === 'contact' ? 'text-agreen font-bold' : ''
              }`}
            >
             {t('navbar.reachUs')}
            </Link>
          </li>
          <li className="md:my-0 my-7 ml-8">
            <LanguageSwitcher/>
          </li>


        </ul>
      </div>
      <hr className='border border-agreen'/>
    </div>  )
}

export default Navbar
