import React from 'react'
import Aquarelle from '../images/Aquarelle5.png'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useState} from 'react'
import {
  faWaterLadder,
  faSquareParking,
  faMugSaucer,
  faHouse,
  faPaw
} from '@fortawesome/free-solid-svg-icons'

const Home = () => {
  const {t} = useTranslation()

  const [showWaterLadderCard, setShowWaterLadderCard] = useState(false)

  const handleWaterLadderIconHover = () => {
    setShowWaterLadderCard(true)
  }

  const handleWaterLadderIconLeave = () => {
    setShowWaterLadderCard(false)
  }

  const [showParkingCard, setShowParkingCard] = useState(false)

  const handleParkingIconHover = () => {
    setShowParkingCard(true)
  }

  const handleParkingIconLeave = () => {
    setShowParkingCard(false)
  }

  const [showMugCard, setShowMugCard] = useState(false)

  const handleMugIconHover = () => {
    setShowMugCard(true)
  }

  const handleMugIconLeave = () => {
    setShowMugCard(false)
  }

  const [showHouseCard, setShowHouseCard] = useState(false)

  const handleHouseIconHover = () => {
    setShowHouseCard(true)
  }

  const handleHouseIconLeave = () => {
    setShowHouseCard(false)
  }

  const [showPawCard, setShowPawCard] = useState(false)

  const handlePawIconHover = () => {
    setShowPawCard(true)
  }

  const handlePawIconLeave = () => {
    setShowPawCard(false)
  }

  return (
    <>
    <main className='text-black'>
      <section>
      <div className="w-full h-screen z-[-1]">
          <img
            src={Aquarelle}
            alt=""
            className="w-screen h-screen object-cover relative"
          />

          <div className="h-1/4 absolute top-52 right-0">
            <div className="text-3xl md:text-7xl p-4 flex mr-5 md:mr-8 ml-[50%] justify-end text-end">
              <p>{t('home.title')}</p>
            </div>
            <div className="flex mr-5 md:mr-8 ml-[50%] justify-end text-end">
              <Link to="/contact">
                <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 mt-8 mr-5 hover:bg-agreen rounded-full duration-500">
                  {t('home.book')}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className="md:px-80 px-12 pt-32 bg-awhite pb-16">
        <div className="flex flex-row justify-center">
          <div
            className="relative"
            onMouseEnter={handleWaterLadderIconHover}
            onMouseLeave={handleWaterLadderIconLeave}
          >
            <FontAwesomeIcon
              icon={faWaterLadder}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 hover:text-agreen cursor-pointer"
            />
            {showWaterLadderCard && (
              <div className="md:left-[-50%] cursor-pointer left-[0%] absolute md:w-[300px] md:h-[220px] w-[400%] md:top-[-500%] top-[-300%] p-10 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-sm md:text-base text-black">
                  {t('home.cards.swim')}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleMugIconHover}
            onMouseLeave={handleMugIconLeave}
          >
            <FontAwesomeIcon
              icon={faMugSaucer}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 hover:text-agreen cursor-pointer "
            />
            {showMugCard && (
              <div className="md:left-[-50%] cursor-pointer left-[-100%] absolute md:w-[300px] md:h-[220px] w-[400%] md:top-[-500%] top-[-300%] p-10 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t('home.cards.breakfast')}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleHouseIconHover}
            onMouseLeave={handleHouseIconLeave}
          >
            <FontAwesomeIcon
              icon={faHouse}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 hover:text-agreen cursor-pointer"
            />
            {showHouseCard && (
              <div className="md:left-[-50%] cursor-pointer left-[-200%] absolute md:w-[300px] md:h-[220px] w-[400%] md:top-[-500%] top-[-300%] p-10 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t('home.cards.house')}
                </p>
              </div>
            )}
          </div>

        </div>
        <div className="flex flex-row justify-center py-20 ">
          <div
            className="relative"
            onMouseEnter={handlePawIconHover}
            onMouseLeave={handlePawIconLeave}
          >
            <FontAwesomeIcon
              icon={faPaw}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 hover:text-agreen cursor-pointer"
            />
            {showPawCard && (
              <div className="md:left-[-50%] left-[-50%] cursor-pointer absolute md:w-[300px] md:h-[220px] w-[400%] md:top-[-500%] top-[-750%] p-10 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t('home.cards.pets')}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative"
            onMouseEnter={handleParkingIconHover}
            onMouseLeave={handleParkingIconLeave}
          >
            <FontAwesomeIcon
              icon={faSquareParking}
              className="md:mx-12 mx-5 text-xl md:text-5xl md:w-16 z-1 cursor-pointer hover:text-agreen"
            />
            {showParkingCard && (
              <div className="md:left-[-50%] left-[-250%] cursor-pointer absolute md:w-[300px] md:h-[220px] w-[400%] md:top-[-500%] top-[-750%] p-10 bg-awhite border-2 border-agreen shadow-lg rounded-lg z-10">
                <p className="font-text text-black text-sm md:text-base">
                  {t('home.cards.parking')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr className='border-2 border-agreen'/>


        <p className="text-4xl flex justify-center text-center font-title text-agreen pt-20 mx-8">
          {t('home.cocoon')}
        </p>
        <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title mx-8 md:mt-10 mt-5 md:mx-10">
        {t('home.rent-sub')}
        </p>

        <div className="md:flex md:mx-40 mx-12">
          <p className="font-text  md:w-1/3 pt-12 md:mx-20 text-justify">
         {t('home.rent-desc-1')}
         <br />
         {t('home.rent-desc-1-2')}
         <br />
         {t('home.rent-desc-1-3')}
          </p>
          <p className="font-text  md:w-1/3 pt-12 md:mx-20 text-justify">
            {t('home.rent-desc-2')}
            <br />
            {t('home.rent-desc-2-2')}
            <br />
          <Link to="https://www.quintadosribeiros.com/region/" target="_blank" className='text-agreen underline-offset-4 underline active:font-bold hover:no-underline'>

                {t('home.rent-farm-link')}


          </Link>
          </p>
        </div>

        <div>
          <p className="flex justify-center md:text-center text-justify md:text-2xl text-lg font-title md:mt-10 mt-5 md:mx-10 mx-8">
            {t('home.interested')}
          </p>
          <div className="flex justify-center py-12 pb-20 rounded-full">
            <Link to="/contact">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
                {t('home.contact')}
              </button>
            </Link>
            <Link to="/photos">
              <button className="bg-ayellow hover:text-awhite font-title font-bold py-2 px-4 rounded-full md:mx-10 mx-4 hover:bg-agreen duration-500">
               {t('home.photos')}
              </button>
            </Link>
          </div>
        </div>


    </main>

    </>
  )
}

export default Home
